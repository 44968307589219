import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { SECTION_BG_COLOR } from 'components/shared/prismic/styled'
import { InstructionsSection, InstructionsSectionPropTypes } from 'components/shared/prismic/InstructionsSection'
import { Section } from '@vizeat/components/es6/components/Section'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Box } from '@vizeat/components/es6/components/Box'
import { Heading } from '@vizeat/components/es6/components/Heading'
import { ItemsList } from '@vizeat/components/es6/components/ItemsList'
import IconCheck from '@vizeat/components/es6/assets/icons/IconCheck'

const { default: defaultBackgroundColor, ...sectionBgColors } = SECTION_BG_COLOR
export function BenefitsSection({ staticFields, benefits }) {
  const list = useMemo(
    () =>
      benefits.map(({ title, list }) => ({
        title,
        items: list.map((text) => ({ text, icon: <IconCheck color='turquoise' mr='12px' /> })),
      })),
    [benefits],
  )

  if (list.length === 0) return null

  return (
    <>
      <InstructionsSection staticFields={staticFields} />
      <Section
        bg={sectionBgColors[staticFields.background_color] || defaultBackgroundColor}
        innerContainerStyles='padding-top: 0; padding-bottom: 0;'
      >
        <Flex
          width='100%'
          flexDirection={{ default: 'column', tablet: 'row' }}
          justifyContent={{ default: 'center', desktop: 'space-around' }}
          gap='32px'
        >
          {list.map(({ title, items }) => (
            <Box
              key={title}
              bg='white'
              width={{ default: '100%', desktop: '250px' }}
              borderRadius='lg'
              boxShadow='md'
              p='16px'
            >
              <Heading as='h5' type='small' mb='16px'>
                {title}
              </Heading>
              <ItemsList items={items} />
            </Box>
          ))}
        </Flex>
      </Section>
    </>
  )
}

BenefitsSection.propTypes = {
  staticFields: InstructionsSectionPropTypes.staticFields.isRequired,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
}
