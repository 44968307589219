// libs
import React, { useEffect } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import { withTranslation } from 'next-i18next'
// hooks
import { useCurrency } from 'hooks/currencies/useCurrency'
import { useHasLoadingSucceeded } from 'hooks'
import { useLanguage } from 'hooks/languages/useLanguage'
// components
import { Modal } from '@vizeat/components/es6/components/Modal'
// redux
import { createPartner } from 'redux/actions'
import { creatingPartner, getCreatePartnerError } from 'redux/selectors'
import { Formik } from 'formik'
import { RegisterPartnerForm, RegisterPartnerFormSchema } from './RegisterPartnerForm'

// poeditor
__('FormField::Required field')
__('FormField::Too long value')
__('FormField::You must approve the contract')
__('FormField::You must select a value in the list')

function _RegisterPartnerFormModal({ t, onHide, show, afterSubmit }) {
  const { appCurrency } = useCurrency()
  const { appLanguage } = useLanguage()
  const isCreatingPartner = useSelector(creatingPartner)
  const apiError = useSelector(getCreatePartnerError)
  const dispatch = useDispatch()
  const hasCreatedPartner = useHasLoadingSucceeded(isCreatingPartner, apiError)

  useEffect(() => {
    if (hasCreatedPartner) afterSubmit()
  }, [afterSubmit, hasCreatedPartner])

  function handleSubmit(values) {
    dispatch(
      createPartner({
        civility: values.civility,
        firstname: values.firstname,
        lastname: values.lastname,
        password: values.password,
        email: values.email,
        phone: values.phone,
        desired_organization: values.agencyName,
        id_number: values.idNumber,
        currency_id: appCurrency.id,
        language_id: appLanguage.id,
      }),
    )
  }

  return (
    <Modal
      title={t('Partner::RegisterModal::Get started')}
      onCancel={onHide}
      onHide={onHide}
      show={show}
      mobileFullScreen
    >
      <Formik
        initialValues={{
          civility: 'MR',
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          phone: '',
          agencyName: '',
          idNumber: '',
          isContractApproved: false,
        }}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur
        validationSchema={RegisterPartnerFormSchema}
        component={RegisterPartnerForm}
      />
    </Modal>
  )
}

_RegisterPartnerFormModal.propTypes = {
  t: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  afterSubmit: PropTypes.func.isRequired,
}

export const RegisterPartnerFormModal = withTranslation()(_RegisterPartnerFormModal)
