import React from 'react'
import PropTypes from 'helpers/proptypes'
import { ButtonWithLoader } from '@vizeat/components/es6/components/ButtonWithLoader'
import { Checkbox } from '@vizeat/components/es6/components/Checkbox'
import { Input } from '@vizeat/components/es6/components/Input'
import { Select } from '@vizeat/components/es6/components/Select'
import { PhoneInput } from '@vizeat/components/es6/components/PhoneInput'
import { Text } from '@vizeat/components/es6/components/Text'
import { Box } from '@vizeat/components/es6/components/Box'
import { withTranslation, Trans } from 'next-i18next'
import * as Yup from 'yup'

export const RegisterPartnerFormSchema = Yup.object().shape({
  civility: Yup.mixed()
    .oneOf(['MR', 'MRS', 'OTHER'], __('FormField::You must select a value in the list'))
    .required(__('FormField::Required field')),
  firstname: Yup.string().max(250, __('FormField::Too long value')).required(__('FormField::Required field')),
  lastname: Yup.string().max(250, __('FormField::Too long value')).required(__('FormField::Required field')),
  email: Yup.string().email(__('FormField::Wrong format')).required(__('FormField::Required field')),
  password: Yup.string().max(250, __('FormField::Too long value')).required(__('FormField::Required field')),
  agencyName: Yup.string().max(250, __('FormField::Too long value')).required(__('FormField::Required field')),
  isContractApproved: Yup.bool().oneOf([true], 'FormField::You must approve the contract'),
})

function _RegisterPartnerForm({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  t,
  setFieldError,
  setFieldValue,
  setFieldTouched,
}) {
  const handlePhoneChange = (value, isValid) => {
    setFieldError('phone', !isValid && t('FormField::Please enter a valid phone number'))
    setFieldValue('phone', value, isValid)
  }
  const handlePhoneBlur = () => setFieldTouched('phone', true, false)
  const handleCivilityChange = ({ value }) => setFieldValue('civility', value, true)
  const handleCivilityBlur = () => setFieldTouched('civility', true, false)

  return (
    <form onSubmit={handleSubmit}>
      <Select
        error={errors.civility && touched.civility && t(errors.civility)}
        isRequired
        label={t('PartnerApplicants::RegisterModal::Civility')}
        name='civility'
        onBlur={handleCivilityBlur}
        onChange={handleCivilityChange}
        options={[
          { value: 'MR', label: t('PartnerApplicants::RegisterModal::Mr') },
          { value: 'MRS', label: t('PartnerApplicants::RegisterModal::Mrs') },
          { value: 'OTHER', label: t('PartnerApplicants::RegisterModal::Other') },
        ]}
        value={values.civility}
      />

      <Input
        error={errors.firstname && touched.firstname && t(errors.firstname)}
        isRequired
        label={t('PartnerApplicants::RegisterModal::First name')}
        name='firstname'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={t('PartnerApplicants::RegisterModal::First name')}
        value={values.firstname}
      />

      <Input
        error={errors.lastname && touched.lastname && t(errors.lastname)}
        isRequired
        label={t('PartnerApplicants::RegisterModal::Last name')}
        name='lastname'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={t('PartnerApplicants::RegisterModal::Last name')}
        value={values.lastname}
      />

      <Input
        autoComplete='nope'
        error={errors.email && touched.email && t(errors.email)}
        isRequired
        label={t('PartnerApplicants::RegisterModal::Email address')}
        name='email'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={t('PartnerApplicants::RegisterModal::Email address')}
        value={values.email}
      />

      <Input
        autoComplete='password'
        error={errors.password && touched.password && t(errors.password)}
        isRequired
        label={t('PartnerApplicants::RegisterModal::Password')}
        name='password'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={t('PartnerApplicants::RegisterModal::Password')}
        type='password'
        value={values.password}
      />

      <PhoneInput
        error={!!errors.phone && touched.phone && t(errors.phone)}
        isRequired
        label={t('PartnerApplicants::RegisterModal::Phone number')}
        name='phone'
        onBlur={handlePhoneBlur}
        onChange={handlePhoneChange}
        value={values.phone}
      />

      <Input
        error={errors.agencyName && touched.agencyName && t(errors.agencyName)}
        isRequired
        label={t('PartnerApplicants::RegisterModal::Agency name')}
        name='agencyName'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={t('PartnerApplicants::RegisterModal::Agency name')}
        values={values.agencyName}
      />

      <Input
        error={errors.idNumber && touched.idNumber && t(errors.idNumber)}
        label={t('PartnerApplicants::RegisterModal::ID number')}
        name='idNumber'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={t('PartnerApplicants::RegisterModal::ID Number (IATA / CLIA / ARC)')}
        value={values.idNumber}
      />

      <Checkbox
        name='isContractApproved'
        value={values.isContractApproved}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.isContractApproved && touched.isContractApproved && t(errors.isContractApproved)}
        label={
          <Text as='span'>
            <Trans
              i18nKey={__(
                'PartnerApplicants::RegisterModal::I agree to the <termsAndConditionsURL>terms & conditions</termsAndConditionsURL> for Eatwith travel agent',
              )}
              components={{
                termsAndConditionsURL: (
                  <a
                    href='https://frozen.eatwith.com/Contract%20Travel%20Agent%20LP%20with%20self%20billing.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  />
                ),
              }}
            />
          </Text>
        }
      />
      <Box my='16px'>
        <ButtonWithLoader green type='submit' fullWidth>
          {t('PartnerApplicants::RegisterModal::Continue')}
        </ButtonWithLoader>
      </Box>
    </form>
  )
}

export const RegisterPartnerForm = withTranslation()(_RegisterPartnerForm)

_RegisterPartnerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  values: PropTypes.shape({
    civility: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    phone: PropTypes.string,
    agencyName: PropTypes.string,
    idNumber: PropTypes.string,
    isContractApproved: PropTypes.bool,
  }).isRequired,
  touched: PropTypes.shape({
    civility: PropTypes.bool,
    firstname: PropTypes.bool,
    lastname: PropTypes.bool,
    email: PropTypes.bool,
    password: PropTypes.bool,
    phone: PropTypes.bool,
    agencyName: PropTypes.bool,
    idNumber: PropTypes.bool,
    isContractApproved: PropTypes.bool,
  }).isRequired,
  errors: PropTypes.shape({
    civility: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    phone: PropTypes.string,
    agencyName: PropTypes.string,
    idNumber: PropTypes.string,
    isContractApproved: PropTypes.string,
  }).isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
}
