
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/landings/travel-agents",
      function () {
        return require("private-next-pages/landings/travel-agents.jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/landings/travel-agents"])
      });
    }
  