// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import { withTranslation } from 'next-i18next'
// components
import { Modal } from '@vizeat/components/es6/components/Modal'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Text } from '@vizeat/components/es6/components/Text'
import { ButtonLink } from 'components/shared/ButtonLink'

export function _PartnerFormSuccessModal({ t, onHide, show }) {
  return (
    <Modal
      show={show}
      onCancel={onHide}
      onHide={onHide}
      confirmLabel={t('Modal::RegisterPartner::Close')}
      title={t('Welcome to the Eatwith Travel Agent community!')}
      mobileFullScreen
    >
      <Flex flexDirection='column' justifyContent='flex-start' my='16px'>
        <Text>
          {t(
            "Partners::SuccessModal::Log in to your account now to access the world's largest marketplace for culinary experiences with locals.",
          )}
        </Text>
        <Text>
          <b>{t('Partners::SuccessModal::How does it work?')}</b>{' '}
          {t(
            'Partners::SuccessModal::It’s as easy as pie: Search for your client’s destination, travel dates and number of guests, select your experience and finalize your booking. It’s really that simple!',
          )}
        </Text>
      </Flex>
      <Flex justifyContent='center'>
        <ButtonLink href='/?login=0'>{t('Partners::SuccessModal::Log in to my account')}</ButtonLink>
      </Flex>
    </Modal>
  )
}

_PartnerFormSuccessModal.propTypes = {
  t: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export const PartnerFormSuccessModal = withTranslation()(_PartnerFormSuccessModal)
