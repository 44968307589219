import React, { useState } from 'react'
import PropTypes, { prismicPropTypes } from 'helpers/proptypes'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { fetchPrismicPartnerPage } from 'services/prismic'
import { initApplication } from 'helpers/SSR'
import { DefaultLayout } from 'layouts/DefaultLayout'
import { Header } from 'layouts/Header'
import { Footer } from 'layouts/Footer'
import {
  PrismicHeroSection,
  HowItWorksSection,
  PlaylistEventsSection,
  ArticleSection,
  ReviewsSection,
  TestimonialsSection,
  CTASection,
  PressSection,
  VideoSection,
} from 'components/shared/prismic'
import { BenefitsSection } from 'components/pages/landings/travel-agents/sections/BenefitsSection'
import { RegisterPartnerFormModal } from 'components/pages/landings/travel-agents/form/RegisterPartnerFormModal'
import { PartnerFormSuccessModal } from 'components/pages/landings/travel-agents/form/PartnerFormSuccessModal'

export const getStaticProps = async ({ locale, previewData }) => {
  initApplication({ locale })

  const partnersData = await fetchPrismicPartnerPage({ locale, previewData })
  if (!partnersData?.length) return { notFound: true }

  const { data, benefits } = partnersData.reduce(
    ({ data, benefits }, slice) => {
      if (slice.slice_type === 'list_section')
        return {
          data,
          benefits: benefits.concat({
            title: slice.primary.title,
            list: slice.items.map(({ list_item_text: text }) => text),
          }),
        }

      return { data: data.concat(slice), benefits }
    },
    { data: [], benefits: [] },
  )

  return {
    props: {
      data,
      benefits,
      ...(await serverSideTranslations(locale || 'en')),
    },
  }
}

export default function Partners({ data, benefits }) {
  const { t } = useTranslation()
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false)
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)

  const handleRegisterModalAppearance = () => setIsRegisterModalVisible(!isRegisterModalVisible)
  const handleSuccessModalAppearance = () => setIsSuccessModalVisible(!isSuccessModalVisible)

  return (
    <>
      <DefaultLayout title={t('Meta::Title::Partners::Eatwith - Travel Agents')} bg='white' showSignupBanner={false}>
        <Header isTransparent customLogo='/assets/images/logo-eatwith-partner-intl-2022-white.svg' />
        {data.map((section, i) => {
          switch (section.slice_type) {
            case 'hero_section':
              return (
                <PrismicHeroSection
                  key={`${section.slice_type}-${i}`}
                  staticFields={section.primary}
                  onCallToActionClick={handleRegisterModalAppearance}
                />
              )
            case 'how_it_works_section':
              return (
                <HowItWorksSection
                  key={`${section.slice_type}-${i}`}
                  items={section.items}
                  staticFields={section.primary}
                />
              )
            case 'top_experiences_section':
              return <PlaylistEventsSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
            case 'article_section':
              return <ArticleSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
            case 'video_section':
              return <VideoSection key={`${section.slice_type}-${i}`} staticFields={section.primary} />
            case 'reviews_section':
              return (
                <ReviewsSection
                  key={`${section.slice_type}-${i}`}
                  items={section.items}
                  staticFields={section.primary}
                />
              )
            case 'instruction_section':
              return (
                <BenefitsSection
                  key={`${section.slice_type}-${i}`}
                  staticFields={section.primary}
                  benefits={benefits}
                />
              )
            case 'cta_section':
              return (
                <CTASection
                  key={`press_${i}`}
                  staticFields={section.primary}
                  onCallToActionClick={handleRegisterModalAppearance}
                />
              )
            case 'testimonial_section':
              return (
                <TestimonialsSection
                  key={`${section.slice_type}-${i}`}
                  staticFields={section.primary}
                  items={section.items}
                />
              )
            case 'press_section':
              return <PressSection key={`${section.slice_type}-${i}`} items={section.items} />
            default:
              return null
          }
        })}
        <Footer />
      </DefaultLayout>

      <RegisterPartnerFormModal
        show={isRegisterModalVisible}
        onHide={handleRegisterModalAppearance}
        afterSubmit={() => {
          handleRegisterModalAppearance()
          handleSuccessModalAppearance()
        }}
      />

      <PartnerFormSuccessModal show={isSuccessModalVisible} onHide={handleSuccessModalAppearance} />
    </>
  )
}

Partners.propTypes = {
  ...prismicPropTypes,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
}
